<template>
  <v-sheet
    height="calc(100vh - 66px)"
    class="overflow-hidden my-2"
    style="position: relative"
  >
    <div>
      <div class="live-tracking-google-map">
        <GmapMap
          ref="map"
          :center="mapCenter"
          :zoom="mapZoom"
          style="width: 100%; height: 100%"
          @ready="onMapReady"
        >
          <GmapCluster :minimumClusterSize="2" :zoomOnClick="true">
            <GmapMarker
              v-for="(marker, index) in markers"
              :key="index"
              :position="marker.position"
              :clickable="true"
              @mouseover="openInfoWindow(index)"
              class="map_marker"
              :icon="markerIcon(marker, index)"
            />
          </GmapCluster>
          <GmapInfoWindow
            v-if="infoWindowOpened"
            :position="markers[activeMarkerIndex].position"
            :options="{ pixelOffset: { width: 0, height: -40 } }"
            class="px-0"
          >
            <div class="custom-info-window">
              <template
                v-if="
                  markers[activeMarkerIndex].type === 'engineer' ||
                  hoveredType === 'engineer'
                "
              >
                <v-toolbar
                  elevation="0"
                  :color="`${markers[activeMarkerIndex].content.visitStatus} lighten-5`"
                >
                  <v-toolbar-title class="text-h6">
                    <v-list-item>
                      <v-badge
                        bordered
                        bottom
                        :color="
                          markers[activeMarkerIndex].content.online
                            ? 'green'
                            : 'red'
                        "
                        dot
                        offset-x="10"
                        offset-y="10"
                        class="me-2"
                      >
                        <v-avatar size="40">
                          <v-img
                            :src="markers[activeMarkerIndex].content.avatar"
                          ></v-img>
                        </v-avatar>
                      </v-badge>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          markers[activeMarkerIndex].content.name
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >{{ markers[activeMarkerIndex].content.profile }}
                          <div>
                            <v-icon size="16">mdi-calendar</v-icon>18/03/2025
                          </div></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <div class="total-visits">
                    <!-- Engineer Visits Summary -->
                    <div class="d-flex align-center justify-end mb-1">
                      <div class="font-weight-medium me-2">Total Visits :</div>
                      <v-avatar class="white--text" color="blue" size="25">
                        <span class="font-weight-medium"> 4 </span>
                      </v-avatar>
                    </div>
                    <div class="d-flex align-center justify-content-end">
                      <div class="font-weight-medium me-2">
                        Overdue Visits :
                      </div>
                      <v-avatar class="white--text" color="red" size="25">
                        <span class="font-weight-medium">1</span>
                      </v-avatar>
                    </div>
                  </div>
                </v-toolbar>
                <div class="px-2 py-2">
                  <!-- Table for Engineer's Job Visits -->
                  <table width="100%" cellpadding="0" cellspacing="0">
                    <tr
                      v-for="(visit, index) in markers[activeMarkerIndex]
                        .content.visits"
                      :key="index"
                      :class="index % 2 === 0 ? 'grey lighten-4' : ''"
                    >
                      <td>
                        <v-chip
                          :color="visit.visitStatus"
                          label
                          x-small
                          text-color="white"
                        >
                          {{ visit.visitId }}
                        </v-chip>
                      </td>
                      <td width="150px">
                        <div
                          class="d-inline-block text-truncate"
                          style="max-width: 150px"
                        >
                          {{ visit.title }}
                        </div>
                      </td>
                      <td>
                        <v-icon :color="visit.visitStatus" small
                          >mdi-map-marker</v-icon
                        >
                        {{ visit.location }}
                      </td>
                      <td>
                        <div>
                          <v-icon class="me-1" small>mdi-clock-outline</v-icon>
                          {{ visit.time }}
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </template>
            </div>
          </GmapInfoWindow>
        </GmapMap>
      </div>
    </div>

    <v-navigation-drawer width="300" v-model="leftDrawer" absolute>
      <engineer-sidebar />
    </v-navigation-drawer>

    <v-navigation-drawer width="300" right v-model="rightDrawer" absolute>
      <visit-sidebar />
    </v-navigation-drawer>
  </v-sheet>
</template>
<script>
import EngineerSidebar from "@/view/pages/live-tracking/engineer-sidebar";
import VisitSidebar from "@/view/pages/live-tracking/visit-sidebar";
import GmapCluster from "vue2-google-maps/dist/components/cluster";

const ENGINEER_SVG = `
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 36">
    <defs>
      <pattern id="imgPattern" patternUnits="userSpaceOnUse" width="20" height="20">
        <image href="{imageUrl}" alt="avatar" width="20" height="20" x="0" y="0" preserveAspectRatio="xMidYMid meet" />
      </pattern>
    </defs>
    <path d="M12 2C7 2 3 6 3 11c0 7.5 9 22 9 22s9-14.5 9-22c0-5-4-9-9-9zm0 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" fill="{backgroundColor}" />
    <circle cx="12" cy="11" r="7" fill="url(#imgPattern)" />
  </svg>`;

const DEFAULT_SVG = `
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="32.000000pt" height="32.000000pt" viewBox="0 0 32.000000 32.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)" fill="{backgroundColor}" stroke="none">
      <path d="M113 310 c-15 -6 -20 -15 -16 -30 3 -11 9 -23 14 -26 16 -10 9 -42 -16 -72 -39 -46 -30 -76 25 -84 22 -3 25 -9 28 -45 4 -55 20 -55 24 -1 3 37 6 43 28 46 55 8 64 38 25 84 -29 34 -30 45 -10 84 8 16 11 32 6 37 -13 13 -83 18 -108 7z"/>
    </g>
  </svg>`;

export default {
  name: "live-tracking",
  data() {
    return {
      leftDrawer: true,
      rightDrawer: true,
      mapZoom: 12,
      markers: [
        {
          type: "engineer",
          position: { lat: 1.3521, lng: 103.8198 },
          img: "/media/users/100_1.jpg",
          visitStatus: "#2588e5",
          content: {
            visitId: 145,
            name: "John Doe",
            profile: "Chief Engineer",
            avatar: "/media/users/100_1.jpg",
            visitStatus: "#2588e5",
            online: true,
            visits: [
              {
                location: "Singapore Business Park",
                visitStatus: "blue",
                visitId: "#Visit-1452",
                title: "Aircon Repair3",
                time: "09:30AM - 10:30AM",
              },
              {
                location: "Singapore Business Park",
                visitStatus: "blue",
                visitId: "#Visit-1453",
                title: "Aircon Repair4",
                time: "09:30AM - 10:30AM",
              },
              {
                location: "Singapore Business Park",
                visitStatus: "blue",
                visitId: "#Visit-1454",
                title: "Aircon Repair5",
                time: "09:30AM - 10:30AM",
              },
              {
                location: "Singapore Business Park",
                visitStatus: "blue",
                visitId: "#Visit-1455",
                title: "Aircon Repair6",
                time: "09:30AM - 10:30AM",
              },
              {
                location: "Singapore Business Park",
                visitStatus: "blue",
                visitId: "#Visit-1456",
                title: "Aircon Repair7",
                time: "09:30AM - 10:30AM",
              },
            ],
          },
        },
      ],
      activeMarkerIndex: null,
      hoveredType: null,
      infoWindowOpened: false,
      mapCenter: { lat: 1.29027, lng: 103.851959 },
    };
  },
  components: {
    GmapCluster,
    EngineerSidebar,
    VisitSidebar,
  },
  methods: {
    markerIcon(marker) {
      const {
        visitStatus: backgroundColor,
        type: locationType,
        imgBase64,
        img,
      } = marker;
      const imageUrl = imgBase64 || img;
      const isEngineer = locationType === "engineer";

      const config = {
        scaledSize: {
          width: isEngineer ? 50 : 30,
          height: isEngineer ? 50 : 30,
        },
        labelOrigin: { x: 12, y: 36 },
      };

      const svgTemplate = isEngineer ? ENGINEER_SVG : DEFAULT_SVG;
      const svgContent = svgTemplate
        .replace("{backgroundColor}", backgroundColor)
        .replace("{imageUrl}", isEngineer ? imageUrl : "");

      return {
        ...config,
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
          svgContent
        )}`,
      };
    },
    openInfoWindow(index) {
      this.activeMarkerIndex = index;
      this.infoWindowOpened = true;
    },
    closeInfoWindow() {
      this.infoWindowOpened = false;
    },
    onMapReady(map) {
      console.log("Map is ready!", map);
    },
  },
};
</script>
