<template>
  <v-card class="overflow-hidden live-tracking-sidebar px-2">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <span
          class="black--text d-flex align-center w-100 border-bottom live-tracking-sidebar-menu"
          v-bind="attrs"
          v-on="on"
        >
          <span class="font-14 font-weight-bold">{{
            engineerStatus?.text
          }}</span>
          <v-avatar class="mx-2" size="23" :color="engineerStatus?.color">
            <span class="white--text font-weight-medium">{{
              engineerStatus?.count
            }}</span>
          </v-avatar>
          <v-icon>mdi-chevron-down</v-icon>
        </span>
      </template>
      <v-list tile>
        <v-list-item
          v-for="(row, index) in engineerStatusList"
          :key="`engineer-status-list-${index}`"
          @click="updateEngineerStatus(row)"
          link
        >
          <v-list-item-title class="font-14">
            {{ row.text }}
          </v-list-item-title>
          <v-list-item-avatar class="my-0">
            <v-avatar size="23" :color="row.color">
              <span class="white--text font-weight-medium">{{
                row.count
              }}</span>
            </v-avatar>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-sheet height="calc(100vh - 111px)" class="overflow-y-auto">
      <v-text-field
        dense
        filled
        solo
        flat
        color="cyan"
        hide-details
        v-model="search"
        clearable
        placeholder="Search engineers..."
        append-icon="mdi-magnify"
        @input="filterEngineer"
        class="my-3"
        :loading="engineerLoading"
      />
      <div>
        <div
          v-if="engineerLoading"
          class="d-flex justify-center align-center my-4"
        >
          <v-progress-circular indeterminate color="cyan" />
        </div>
        <div v-else>
          <v-list
            tile
            style="
              max-height: calc(100vh - 168px);
              overflow-y: scroll;
              padding: 0;
            "
          >
            <v-list-item
              v-for="(row, index) in engineers"
              :key="`live-tracking-engineers-${index}`"
              class="border-top"
            >
              <v-list-item-avatar>
                <v-img
                  :src="row?.profile_logo?.file?.url ?? $defaultProfileImage"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >{{ row.display_name }}
                  <v-icon color="green">mdi-access-point</v-icon>
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  row?.designation?.text
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar size="20"
                ><v-avatar size="20" class="white--text" color="blue">{{
                  row?.visit_count
                }}</v-avatar>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </v-sheet>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import {
  UPDATE_ENGINEER_STATUS,
  FILTER_ENGINEER,
  GET_ENGINEER_STATUS,
  SET_ENGINEERS,
  SET_LOADER,
} from "@/core/services/store/tracking.module";

export default {
  name: "live-tracking-engineer-sidebar",
  data() {
    return {
      search: null,
      timeout: null,
      timeoutDuration: 500,
    };
  },
  methods: {
    filterEngineer() {
      clearTimeout(this.timeout);
      this.$store.commit(SET_ENGINEERS, []);
      this.$store.commit(SET_LOADER, true);
      this.timeout = setTimeout(() => {
        this.$store.dispatch(FILTER_ENGINEER, { search: this.search });
      }, this.timeoutDuration);
    },
    updateEngineerStatus(row) {
      this.$store.commit(UPDATE_ENGINEER_STATUS, row);
      this.filterEngineer();
    },
    getEngineers() {
      this.$store.dispatch(FILTER_ENGINEER);
    },
    getEngineerStatus() {
      this.$store.dispatch(GET_ENGINEER_STATUS);
    },
  },
  mounted() {
    this.getEngineers();
    this.getEngineerStatus();
  },
  computed: {
    ...mapGetters([
      "engineerStatusList",
      "engineerStatus",
      "engineers",
      "engineerLoading",
    ]),
  },
};
</script>
