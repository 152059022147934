var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticClass:"overflow-hidden my-2",staticStyle:{"position":"relative"},attrs:{"height":"calc(100vh - 66px)"}},[_c('div',[_c('div',{staticClass:"live-tracking-google-map"},[_c('GmapMap',{ref:"map",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.mapCenter,"zoom":_vm.mapZoom},on:{"ready":_vm.onMapReady}},[_c('GmapCluster',{attrs:{"minimumClusterSize":2,"zoomOnClick":true}},_vm._l((_vm.markers),function(marker,index){return _c('GmapMarker',{key:index,staticClass:"map_marker",attrs:{"position":marker.position,"clickable":true,"icon":_vm.markerIcon(marker, index)},on:{"mouseover":function($event){return _vm.openInfoWindow(index)}}})}),1),(_vm.infoWindowOpened)?_c('GmapInfoWindow',{staticClass:"px-0",attrs:{"position":_vm.markers[_vm.activeMarkerIndex].position,"options":{ pixelOffset: { width: 0, height: -40 } }}},[_c('div',{staticClass:"custom-info-window"},[(
                _vm.markers[_vm.activeMarkerIndex].type === 'engineer' ||
                _vm.hoveredType === 'engineer'
              )?[_c('v-toolbar',{attrs:{"elevation":"0","color":`${_vm.markers[_vm.activeMarkerIndex].content.visitStatus} lighten-5`}},[_c('v-toolbar-title',{staticClass:"text-h6"},[_c('v-list-item',[_c('v-badge',{staticClass:"me-2",attrs:{"bordered":"","bottom":"","color":_vm.markers[_vm.activeMarkerIndex].content.online
                          ? 'green'
                          : 'red',"dot":"","offset-x":"10","offset-y":"10"}},[_c('v-avatar',{attrs:{"size":"40"}},[_c('v-img',{attrs:{"src":_vm.markers[_vm.activeMarkerIndex].content.avatar}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.markers[_vm.activeMarkerIndex].content.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.markers[_vm.activeMarkerIndex].content.profile)+" "),_c('div',[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-calendar")]),_vm._v("18/03/2025 ")],1)])],1)],1)],1),_c('v-spacer'),_c('div',{staticClass:"total-visits"},[_c('div',{staticClass:"d-flex align-center justify-end mb-1"},[_c('div',{staticClass:"font-weight-medium me-2"},[_vm._v("Total Visits :")]),_c('v-avatar',{staticClass:"white--text",attrs:{"color":"blue","size":"25"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" 4 ")])])],1),_c('div',{staticClass:"d-flex align-center justify-content-end"},[_c('div',{staticClass:"font-weight-medium me-2"},[_vm._v(" Overdue Visits : ")]),_c('v-avatar',{staticClass:"white--text",attrs:{"color":"red","size":"25"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v("1")])])],1)])],1),_c('div',{staticClass:"px-2 py-2"},[_c('table',{attrs:{"width":"100%","cellpadding":"0","cellspacing":"0"}},_vm._l((_vm.markers[_vm.activeMarkerIndex]
                      .content.visits),function(visit,index){return _c('tr',{key:index,class:index % 2 === 0 ? 'grey lighten-4' : ''},[_c('td',[_c('v-chip',{attrs:{"color":visit.visitStatus,"label":"","x-small":"","text-color":"white"}},[_vm._v(" "+_vm._s(visit.visitId)+" ")])],1),_c('td',{attrs:{"width":"150px"}},[_c('div',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(visit.title)+" ")])]),_c('td',[_c('v-icon',{attrs:{"color":visit.visitStatus,"small":""}},[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(visit.location)+" ")],1),_c('td',[_c('div',[_c('v-icon',{staticClass:"me-1",attrs:{"small":""}},[_vm._v("mdi-clock-outline")]),_vm._v(" "+_vm._s(visit.time)+" ")],1)])])}),0)])]:_vm._e()],2)]):_vm._e()],1)],1)]),_c('v-navigation-drawer',{attrs:{"width":"300","absolute":""},model:{value:(_vm.leftDrawer),callback:function ($$v) {_vm.leftDrawer=$$v},expression:"leftDrawer"}},[_c('engineer-sidebar')],1),_c('v-navigation-drawer',{attrs:{"width":"300","right":"","absolute":""},model:{value:(_vm.rightDrawer),callback:function ($$v) {_vm.rightDrawer=$$v},expression:"rightDrawer"}},[_c('visit-sidebar')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }