<template>
  <v-card class="overflow-hidden live-tracking-sidebar px-2">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <span
          class="black--text d-flex align-center w-100 border-bottom live-tracking-sidebar-menu"
          v-bind="attrs"
          v-on="on"
        >
          <span class="font-14 font-weight-bold">{{
            engineerStatus.text
          }}</span>
          <v-avatar class="mx-2" size="23" :color="engineerStatus.color">
            <span class="white--text font-weight-medium">{{
              engineerStatus.count
            }}</span>
          </v-avatar>
          <v-icon>mdi-chevron-down</v-icon>
        </span>
      </template>
      <v-list tile>
        <v-list-item
          v-for="(row, index) in visitStatusList"
          :key="`engineer-status-list-${index}`"
          @click="updateEngineerStatus(row)"
          link
        >
          <v-list-item-title class="font-14">
            {{ row.text }}
          </v-list-item-title>
          <v-list-item-avatar class="my-0">
            <v-avatar size="23" :color="row.color">
              <span class="white--text font-weight-medium">{{
                row.count
              }}</span>
            </v-avatar>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-sheet height="calc(100vh - 111px)" class="overflow-y-auto">
      <v-text-field
        dense
        filled
        solo
        flat
        color="cyan"
        hide-details
        placeholder="Search visits..."
        append-icon="mdi-magnify"
        class="my-3"
      />
      <div>
        <v-list tile>
          <v-list-item class="border-top">
            <v-list-item-avatar>
              <v-img src="engineer.avatar" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                >HELLO ENGINEER
                <v-icon color="green">mdi-access-point</v-icon>
              </v-list-item-title>
              <v-list-item-subtitle>Senior Engineer</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar
              ><v-avatar size="20" class="white--text" color="blue"
                >20</v-avatar
              >
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
      </div>
    </v-sheet>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import {
  UPDATE_ENGINEER_STATUS,
  FILTER_ENGINEER,
} from "@/core/services/store/tracking.module";
export default {
  name: "live-tracking-visit-sidebar",
  data() {
    return {};
  },
  methods: {
    updateEngineerStatus(row) {
      this.$store.commit(UPDATE_ENGINEER_STATUS, row);
      this.$store.dispatch(FILTER_ENGINEER);
    },
  },
  computed: {
    ...mapGetters(["visitStatusList", "engineerStatus", "engineers"]),
  },
};
</script>
